<template>
  <router-view></router-view>
</template>

<script>
// import './assets/fonts/icon/iconfont'

export default {
  name: 'App',
  components: {
  }
}
</script>

<style lang="less">
@import './assets/fonts/icon/iconfont.css';

</style>
